<template lang="pug">
  div
    v-row
      v-col(cols="12")
        v-row
          v-col(offset-lg="6" cols="3")
            v-btn(
              color="green"
              small
              outlined
              block
              @click="storeVoucher"
              :loading="voucherPosting"
            )
              span save
          v-col(cols="3")
            v-btn(
              color="red"
              small
              outlined
              block
              @click="$router.push('/vouchers')"
            )
              span Cancel
          v-col(lg="3" cols="12").ma-0.py-1
            v-autocomplete(
              label="Pay to"
              :search-input.sync="payToListSearch"
              item-value="id"
              item-text="name"
              v-model="payToObject"
              :items="payToListList"
              :loading="payToListGetting"
              :error-messages="voucherPostErrors.pay_to"
              no-filter
              return-object
              dense
            )
              template(v-slot:item="{ item }")
                span.primary--text {{ item.id }}
                small.ml-2.blue-grey--text {{ item.name }}
                small.ml-2.blue-grey--text ({{ item.type }})
          v-col(lg="3" cols="12").ma-0.py-1
            v-text-field(
              label="Address"
              v-model="address"
              :error-messages="voucherPostErrors.address"
              dense
            )
          v-col(lg="3" cols="12").ma-0.py-1
            v-text-field(
              label="Date"
              type="date"
              v-model="date"
              :error-messages="voucherPostErrors.date"
              dense
            )
          v-col(lg="3" cols="12").ma-0.py-1
            v-autocomplete(
              label="Project Code"
              v-model="projectCodeId"
              item-value="id"
              item-text="description"
              :items="projectCodeIndexList"
              :loading="projectCodeIndexGetting"
              :search-input.sync="searchProjectCode"
              :error-messages="voucherPostErrors.project_code_id"
              clearable
              no-filter
              dense
            )
              tempalte(v-slot:selection="{ item }")
                span.primary--text {{ item.project_code }}
              template(v-slot:item="{ item }")
                span.primary--text {{ item.project_code }}
                small.ml-2.blue-grey--text {{ item.description }}
          v-col(lg="3" cols="12").ma-0.py-1
            v-autocomplete(
              label="Budget Code"
              v-model="budgetCodeId"
              item-value="id"
              item-text="budget_code"
              :items="budgetCodeIndexList"
              :search-input.sync="searchBudgetCode"
              :loading="budgetCodeIndexGetting"
              dense
              :error-messages="voucherPostErrors.budget_code_id"
              no-filter
            )
              template(v-slot:selection="{ item }")
                span {{ item.budget_code }}
              template(v-slot:item="{ item }")
                span.primary--text {{ item.budget_code }}
                small.blue-grey--text.ml-2 {{ item.description }}
          v-col(lg="3" cols="12").ma-0.py-1
            v-autocomplete(
              label="Paid By"
              item-value="id"
              item-text="description"
              :items="voucherPaidByList"
              :loading="voucherPaidByGetting"
              v-model="paidBy"
              dense
              :error-messages="voucherPostErrors.paid_by"
            )
              template(v-slot:item="{ item }")
                span.primary--text {{ item.code }}
                span.ml-2.blue-grey--text {{ item.description }}
          //- v-col(lg="3" cols="12")
          //-   v-text-field(
          //-     label="Approval"
          //-     v-model="approval"
          //-   )
          v-col(lg="3" cols="12").ma-0.py-1
            v-text-field(
              label="Voucher Status"
              readonly
              dense
              :value="voucherStatus.description"
              :error-messages="voucherPostErrors.voucher_status_id"
            )
          v-col(lg="3" cols="12").ma-0.py-1
            v-autocomplete(
              label="Cost Center"
              v-model="costCenterId"
              item-value="id"
              dense
              item-text="description"
              :items="costCenterList"
              :error-messages="voucherPostErrors.cost_center_id"
            )
              template(v-slot:item="{ item }")
                span {{ item.code }}&nbsp;
                small.blue-grey--text.ml-2 {{ item.description }}
            //- v-autocomplete(
            //-   label="Voucher Status"
            //-   :loading="voucherStatusGetting"
            //-   :items="voucherStatusList"
            //-   item-value="id"
            //-   item-text="description"
            //-   v-model="voucherStatusId"
            //- )

            //-   template(v-slot:selection="{ item }")
            //-     span.text-capitalize {{ item.description }}
            //-   template(v-slot:item="{ item }")
            //-     span.text-capitalize {{ item.description }}
      v-col(cols="12")
        v-toolbar(
          color="primary"
          dense
          flat
          dark
        )
          span Details
        voucher-details(
          v-model="voucherDetails"
        )
</template>
<script>
import budgetCodeRepository from '@/repositories/budget-code.repository'
import projectCodeRepository from '@/repositories/project-code.repository'
import voucherStatusRepository from '@/repositories/voucher-status.repository'
import voucherRepository from '@/repositories/voucher.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import voucherPaidByRepository from '@/repositories/voucher-paid-by.repository'
import costCenterRepository from '@/repositories/cost-center.repository'
import { requestVars } from '@/libs/api-helper.extra'

const [payToListGetVars, payToListGetVarNames] = requestVars({ identifier: 'pay-to-list' })
const [voucherStatusGetVars, voucherStatusGetVarNames] = requestVars({ identifier: 'voucher-status' })
const [voucherPostVars, voucherPostVarNames] = requestVars({ identifier: 'voucher', request: 'post', hasData: false })
const [voucherPaidByGetVars, voucherPaidByGetVarNames] = requestVars({ identifier: 'voucher-paid-by' })
const [projectCodeIndexVars, projectCodeIndexVarNames] = requestVars({ identifier: 'project-code-index', pagination: true })
const [budgetCodeIndexVars, budgetCodeIndexVarNames] = requestVars({ identifier: 'budget-code-index' })
const [costCenterGetVars, costCenterGetVarNames] = requestVars({ identifier: 'cost-center' })

const voucherStatusGetHandler = new VueRequestHandler(null, voucherStatusGetVarNames)
const voucherPostHandler = new VueRequestHandler(null, voucherPostVarNames)
const payToListHandler = new VueRequestHandler(null, payToListGetVarNames)
const voucherPaidByHandler = new VueRequestHandler(null, voucherPaidByGetVarNames)
const projectCodeIndexHandler = new VueRequestHandler(null, projectCodeIndexVarNames, {}, true)
const budgetCodeIndexHandler = new VueRequestHandler(null, budgetCodeIndexVarNames)
const costCenterGetHandler = new VueRequestHandler(null, costCenterGetVarNames)

const inputVars = () => ({
  id: null,
  payToObject: {},
  date: Window.getCurrentDate(),
  projectCodeId: null,
  budgetCodeId: null,
  paidBy: null,
  approval: null,
  voucherStatusId: 'NW',
  voucherDetails: [],
  costCenterId: null,
})

export default {
  name: 'CreateVoucher',
  components: {
    voucherDetails: () => import('./details/Index'),
  },
  created() {
    this.getVoucherStatuses()
    this.getPayToList()
    this.voucherPaidBy()
    this.getProjectCodes()
    this.getBudgetCodes()
    this.getCostCenters()
  },
  data() {
    return {
      payToListSearch: null,
      searchProjectCode: null,
      searchBudgetCode: null,
      ...inputVars(),
      ...voucherStatusGetVars,
      ...voucherPostVars,
      ...payToListGetVars,
      ...projectCodeIndexVars,
      ...voucherPaidByGetVars,
      ...budgetCodeIndexVars,
      ...costCenterGetVars,
    }
  },
  computed: {
    address() {
      return this.payToObject ? this.payToObject.address : null
    },
    payTo() {
      return this.payToObject ? this.payToObject.id : null
    },
    payToType() {
      return this.payToObject ? this.payToObject.type : null
    },
    voucherStatus() {
      return this.voucherStatusList.find(item => item.id === this.voucherStatusId) || {}
    },
  },
  watch: {
    payToListSearch() {
      this.getPayToList()
    },
    searchProjectCode() {
      this.getProjectCodes()
    },
    searchBudgetCode() {
      this.getBudgetCodes()
    },
  },
  methods: {
    getCostCenters() {
      const handler = costCenterGetHandler
      const repository = costCenterRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    getBudgetCodes() {
      const handler = budgetCodeIndexHandler
      const repository = budgetCodeRepository.index
      const params = {
        limit: this.itemsPerPage,
        search: this.searchBudgetCode,
      }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    getProjectCodes() {
      const handler = projectCodeIndexHandler
      const repository = projectCodeRepository.index
      const params = {
        search: this.searchProjectCode,
      }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    voucherPaidBy() {
      const handler = voucherPaidByHandler
      const repository = voucherPaidByRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    getVoucherStatuses() {
      const handler = voucherStatusGetHandler
      const repository = voucherStatusRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    getPayToList() {
      const handler = payToListHandler
      const repository = voucherRepository.payToList
      const params = {
        search: this.payToListSearch,
      }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    storeVoucher() {
      const handler = voucherPostHandler
      const repository = voucherRepository.store
      const { data } = this.getInputFields()
      
      handler.setVue(this)
      handler.execute(repository, [data], this.storeVoucherStatusOk)
    },
    storeVoucherStatusOk() {
      this.$router.push('/vouchers')
    },
    getInputFields() {
      const excluded = ['id', 'payToObject']
      const include = ['payTo', 'payToType', 'address']
      const data = Object.keys(inputVars())
        .concat(include)
        .filter(key => !excluded.includes(key))
        .reduce((accum, key) => {
          if (key === 'voucherDetails') {
            accum.details = this.alterVoucherDetailForStoring(this[key])
            return accum
          }
          accum[key.camelToSnakeCase()] = this[key]
          return accum
        }, {})
      const { id } = this
      return { data, id }
    },
    alterVoucherDetailForStoring(data) {
      return data.map(item => Object.keys(item)
        .reduce((accum, key) => {
          const key2 = key.camelToSnakeCase()
          if (key === 'costCenter' || key === 'accountCode') {
            accum[`${key2}_id`] = item[key].id
            return accum
          }
          accum[key2] = item[key]
          return accum
        }, {}))
    },
  },
}
</script>
